import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router-dom for navigation

const ExtraInfoForm = () => {
    const [extrainfoRecords, setExtraInfoRecords] = useState([{
        language: '',
        jobpreference: '',
        skills: '',
    }]);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [username, setUsername] = useState('');
    const navigate = useNavigate(); // React Router navigation hook

    useEffect(() => {
        const key = localStorage.getItem('username');
        setUsername(key || ''); // Default to empty string if not found
    }, []);

    const handleInputChange = (index, event) => {
        const values = [...extrainfoRecords];
        values[index][event.target.name] = event.target.value;
        setExtraInfoRecords(values);
    };

    const handleAddMore = () => {
        setExtraInfoRecords([...extrainfoRecords, {
            jobpreference: '',
            language: '',
            skills: '',
        }]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            for (const record of extrainfoRecords) {
                await axios.post('https://profit-backend.onrender.com/api/extra-info/', {
                    ...record,
                    username,
                });
            }
            setMessage('Additional information submitted successfully!');
            setError('');
        } catch (error) {
            setError('An error occurred while submitting the form.');
            setMessage('');
        }
    };

    const handleSkip = () => {
        navigate('/panel'); // Redirect to home page
    };

    const handleAddAndNext = async () => {
        try {
            await handleSubmit(new Event('submit')); // Submit current data
            navigate('/panel'); // Redirect to next page
        } catch (error) {
            setError('An error occurred while submitting the form.');
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen mt-20 inset-0 z-50 overflow-y-auto">
            <div className="w-full max-w-lg p-8 bg-white shadow-lg rounded-lg">
                <h2 className="text-xl font-medium leading-normal text-gray-800 dark:text-gray-200 text-center">Additional Information</h2>
                <form onSubmit={handleSubmit} className="space-y-6">
                    {extrainfoRecords.map((record, index) => (
                        <div key={index}>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">Job Preference:</label>
                                <input
                                    type="text"
                                    name="jobpreference"
                                    value={record.jobpreference}
                                    onChange={(e) => handleInputChange(index, e)}
                                    required
                                    className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                                    placeholder="e.g., Manager, Lead, etc."
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">Language:</label>
                                <input
                                    type="text"
                                    name="language"
                                    value={record.language}
                                    onChange={(e) => handleInputChange(index, e)}
                                    required
                                    className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                                    placeholder="e.g., Urdu, English"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">Skills:</label>
                                <input
                                    type="text"
                                    name="skills"
                                    value={record.skills}
                                    onChange={(e) => handleInputChange(index, e)}
                                    required
                                    className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                                    placeholder="e.g., I'm expert at....."
                                />
                            </div>
                        </div>
                    ))}
                    <button
                        type="button"
                        onClick={handleAddMore}
                        className="bg-gray-500 text-white px-4 py-2 rounded-full mt-4"
                    >
                        Add More
                    </button>
                    <div className="flex justify-between mt-6">
                        <button
                            type="button"
                            onClick={handleSkip}
                            className="bg-gray-500 text-white px-4 py-2 rounded-full"
                        >
                            Skip
                        </button>
                        <button
                            type="button"
                            onClick={handleAddAndNext}
                            className="bg-gradient-to-br from-green-400 to-blue-600 text-white px-4 py-2 rounded-full"
                        >
                            Add & Next
                        </button>
                    </div>
                </form>
                {message && <p className="mt-4 text-green-600 text-center">{message}</p>}
                {error && <p className="mt-4 text-red-600 text-center">{error}</p>}
            </div>
        </div>
    );
};

export default ExtraInfoForm;