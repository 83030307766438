// import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBookmark, faCheckSquare } from "@fortawesome/free-solid-svg-icons";

// // Job content data
// const contents = [
//   {
//     img: "https://cdn.easyfrontend.com/pictures/logos/instagram-logo.png",
//     title: "User Experience Design Intern, Summer 2019",
//     place: "Instagram",
//     location: "Sydney, AU",
//     duration: "6 Days",
//     applicants: "68",
//     authorImg: "https://cdn.easyfrontend.com/pictures/users/user2.jpg",
//     authorName: "Sara Kleinz",
//     authorEmail: "11shafayet@gmail.com",
//   },
//   {
//     img: "https://cdn.easyfrontend.com/pictures/logos/apple-logo.png",
//     title: "Graphic Design Coordinator, Summer 2020",
//     place: "Apple",
//     location: "London, UK",
//     duration: "3 Days",
//     applicants: "55",
//     authorImg: "https://cdn.easyfrontend.com/pictures/users/user4.jpg",
//     authorName: "Steve Smith",
//     authorEmail: "yourgmail@gmail.com",
//   },
//   {
//     img: "https://cdn.easyfrontend.com/pictures/logos/twitter-logo.png",
//     title: "User Experience Design Intern, Summer 2019",
//     place: "Twitter",
//     location: "Melbourne, AU",
//     duration: "2 Days",
//     applicants: "11",
//     authorImg: "https://cdn.easyfrontend.com/pictures/users/user7.jpg",
//     authorName: "Abraham Finn",
//     authorEmail: "111shafayet@gmail.com",
//   },
// ];

// const CareersPage = () => {
//   return (
//     <section className="ezy__careers15 light py-14 md:py-24 bg-white dark:bg-[#0b1727] text-zinc-900 dark:text-white">
//       <div className="container px-4">
//         <div className="grid grid-cols-12">
//           <div className="col-span-12">
//             <h1 className="text-3xl lg:text-[40px] font-bold mb-12">All Jobs</h1>
//           </div>

//           {contents.map((content, i) => (
//             <div className="col-span-12 mt-6" key={i}>
//               <div className="bg-white shadow-2xl dark:bg-[#1E2735] dark:shadow-none hover:bg-blue-600 hover:bg-opacity-20 dark:hover:bg-blue-600 dark:hover:bg-opacity-20 group hover:scale-[1.01] duration-[.4s] p-4 md:p-6">
//                 <div className="grid grid-cols-12 items-center w-full">
//                   <div className="col-span-12 md:col-span-2">
//                     <div className="w-24 h-24 border border-gray-200 dark:border-gray-700 rounded-full flex justify-center items-center mx-auto">
//                       <img src={content.img} alt="" className="max-w-[50px] h-auto" />
//                     </div>
//                   </div>
//                   <div className="col-span-12 md:col-span-7 mt-1 md:mt-0">
//                     <div>
//                       <h4 className="text-2xl font-bold mb-2">{content.title}</h4>
//                       <h5 className="text-center md:text-start text-xl font-medium mb-2">
//                         <span className="text-blue-600">{content.place}</span> - {content.location}
//                       </h5>
//                     </div>
//                     <div className="text-center md:text-start mt-6">
//                       <p className="opacity-50 mb-2">
//                         Posted {content.duration} ago - {content.applicants} applicants
//                       </p>
//                       <div className="flex justify-center md:justify-start items-center">
//                         <div>
//                           <img
//                             src={content.authorImg}
//                             alt=""
//                             className="rounded-full"
//                             width="50"
//                             height="50"
//                           />
//                         </div>
//                         <div className="ml-2">
//                           <h6 className="font-bold">Contact: {content.authorName}</h6>
//                           <p className="mb-2">{content.authorEmail}</p>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="col-span-12 md:col-span-3 mt-4 md:mt-0">
//                     <div className="flex md:flex-col md:items-center justify-center h-full">
//                       {/* <button className="py-2 px-4 w-36 text-lg border border-gray-200 dark:border-gray-700 opacity-75 transition hover:opacity-100 group-hover:border-gray-400">
//                         Save
//                         <FontAwesomeIcon icon={faBookmark} className="ml-2" />
//                       </button> */}
//                       {/* Apply button with href link */}
//                       <a
//                         href='/research-funding-application'
//                         target="_blank"
//                         rel="noopener noreferrer"
//                         className="py-2 px-4 w-36 text-lg border border-gray-200 dark:border-gray-700 opacity-75 transition hover:opacity-100 group-hover:border-gray-400 ml-3 md:ml-0 md:mt-4 text-center flex items-center justify-center"
//                       >
//                         Apply
//                         <FontAwesomeIcon icon={faCheckSquare} className="ml-2" />
//                       </a>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default CareersPage;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark, faCheckSquare } from "@fortawesome/free-solid-svg-icons";

// Job content data
const contents = [
  {
    img: "https://cdn.easyfrontend.com/pictures/logos/instagram-logo.png",
    title: "Video editor for Social media",
    place: "Profit Compare",
    location: "Pakistan",
    duration: "",
    applicants: "",
    authorImg: "https://cdn.easyfrontend.com/pictures/users/user2.jpg",
    authorName: "Dr Yasser",
    authorEmail: "",
  },
  // {
  //   img: "https://cdn.easyfrontend.com/pictures/logos/apple-logo.png",
  //   title: "Graphic Design Coordinator, Summer 2020",
  //   place: "Apple",
  //   location: "London, UK",
  //   duration: "3 Days",
  //   applicants: "55",
  //   authorImg: "https://cdn.easyfrontend.com/pictures/users/user4.jpg",
  //   authorName: "Steve Smith",
  //   authorEmail: "yourgmail@gmail.com",
  // },
  // {
  //   img: "https://cdn.easyfrontend.com/pictures/logos/twitter-logo.png",
  //   title: "User Experience Design Intern, Summer 2019",
  //   place: "Twitter",
  //   location: "Melbourne, AU",
  //   duration: "2 Days",
  //   applicants: "11",
  //   authorImg: "https://cdn.easyfrontend.com/pictures/users/user7.jpg",
  //   authorName: "Abraham Finn",
  //   authorEmail: "111shafayet@gmail.com",
  // },
];

const CareersPage = () => {
  return (
    <section className="ezy__careers15 light py-10 md:py-16 bg-white dark:bg-[#0b1727] text-zinc-900 dark:text-white">
      <div className="container px-4">
        <br/>
        <br/>
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            <h1 className="text-3xl lg:text-[32px] font-bold mb-8">All Jobs</h1>
          </div>

          {contents.map((content, i) => (
            <div className="col-span-12 mb-4" key={i}>
              <div className="bg-white shadow-md dark:bg-[#1E2735] dark:shadow-none hover:bg-blue-600 hover:bg-opacity-10 dark:hover:bg-blue-600 dark:hover:bg-opacity-10 group hover:scale-[1.01] duration-[.4s] p-3 md:p-4">
                <div className="grid grid-cols-12 items-center w-full">
                  <div className="col-span-2 flex justify-center items-center">
                    <div className="w-16 h-16 border border-gray-200 dark:border-gray-700 rounded-full flex justify-center items-center">
                      <img src={content.img} alt="" className="max-w-[40px] h-auto" />
                    </div>
                  </div>
                  <div className="col-span-7">
                    <div>
                      <h4 className="text-lg font-bold mb-1">{content.title}</h4>
                      <h5 className="text-center md:text-start text-md font-medium mb-1">
                        <span className="text-blue-600">{content.place}</span> - {content.location}
                      </h5>
                    </div>
                    <div className="text-center md:text-start">
                      <p className="opacity-60 text-sm mb-1">
                        Posted {content.duration} ago - {content.applicants} applicants
                      </p>
                      <div className="flex justify-center md:justify-start items-center">
                        <div>
                          <img
                            src={content.authorImg}
                            alt=""
                            className="rounded-full"
                            width="35"
                            height="35"
                          />
                        </div>
                        <div className="ml-2 text-sm">
                          <h6 className="font-semibold">Contact: {content.authorName}</h6>
                          <p>{content.authorEmail}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-3 flex md:flex-col md:items-center justify-center h-full">
                    {/* Apply button with href link */}
                    <a
                      href="/video-editor"
                      rel="noopener noreferrer"
                      className="py-1 px-3 w-28 text-sm border border-gray-200 dark:border-gray-700 opacity-75 transition hover:opacity-100 group-hover:border-gray-400 text-center flex items-center justify-center"
                    >
                      Apply
                      <FontAwesomeIcon icon={faCheckSquare} className="ml-2" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CareersPage;
