// import React, { useState } from "react";

// const ContactForm = () => {
//   const [validated, setValidated] = useState(false);
//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [message, setMessage] = useState("");

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     const form = event.currentTarget;

//     if (form.checkValidity() === false) {
//       event.stopPropagation();
//     } else {
//       // Add any logic you want to execute on valid form submission
//       alert("Form submitted successfully!");

//       // Clear the form fields
//       setName("");
//       setEmail("");
//       setMessage("");
//     }

//     setValidated(true);
//   };

//   return (
//     <form noValidate validated={validated.toString()} onSubmit={handleSubmit}>
//       <div className="mb-4">
//         <input
//           type="text"
//           value={name}
//           onChange={(e) => setName(e.target.value)}
//           className="min-h-[48px] leading-[48px] bg-[#F2F6FD] dark:bg-[#2A384C] border border-transparent rounded-xl focus:outline-none focus:border-[#86b7fe] w-full px-5"
//           placeholder="Enter Name"
//           required
//         />
//       </div>
//       <div className="mb-4">
//         <input
//           type="email"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//           className="min-h-[48px] leading-[48px] bg-[#F2F6FD] dark:bg-[#2A384C] border border-transparent rounded-xl focus:outline-none focus:border-[#86b7fe] w-full px-5"
//           placeholder="Enter Email"
//           required
//         />
//       </div>
//       <div className="mb-4">
//         <textarea
//           value={message}
//           onChange={(e) => setMessage(e.target.value)}
//           name="message"
//           className="min-h-[48px] leading-[48px] bg-[#F2F6FD] dark:bg-[#2A384C] border border-transparent rounded-xl focus:outline-none focus:border-[#86b7fe] w-full px-5"
//           placeholder="Enter Message"
//           rows="4"
//           required
//         ></textarea>
//       </div>
//       <div className="text-end">
//         <button
//           type="submit"
//           className="bg-blue-600 hover:bg-opacity-90 text-white px-9 py-3 rounded-md mb-4"
//         >
//           Submit
//         </button>
//       </div>
//     </form>
//   );
// };

// const ContactFormCard = () => (
//   <div className="bg-white dark:bg-[#162231] shadow-xl rounded-2xl p-6 md:p-12">
//     <h2 className="text-2xl md:text-[45px] leading-none font-bold mb-4">
//       Contact Us
//     </h2>
//     <p className="text-lg mb-12">
//       We list your menu online, help you process orders.
//     </p>

//     <ContactForm />
//   </div>
// );

// const ContactUs3 = () => {
//   return (
//     <section className="ezy__contact3 light py-14 md:py-24 bg-white dark:bg-[#0b1727] text-zinc-900 dark:text-white overflow-hidden">
//       <div className="container px-4">
//         <div className="grid grid-cols-12 py-6 lg:gap-8">
//           <div className="col-span-12 lg:col-span-7 lg:order-2 mb-4 lg:mb-0">
//             <div
//               className="bg-center bg-no-repeat bg-cover rounded-2xl min-h-[150px] h-full object-cover"
//               style={{
//                 backgroundImage:
//                   "url(https://cdn.easyfrontend.com/pictures/contact/contact_3.png)",
//               }}
//             ></div>
//           </div>
//           <div className="col-span-12 lg:col-span-5">
//             <ContactFormCard />
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default ContactUs3;

import React, { useState } from "react";
import ContactImage from '../assets/images/background/Contact.jpeg';

const ContactForm = () => {
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (!form.checkValidity()) {
      setErrorMessage("Please enter a valid email address.");
      event.stopPropagation();
    } else {
      setErrorMessage("");

      // Prepare the data to be sent in the POST request
      const formData = {
        name: name,
        email: email,
        message: message,
      };

      try {
        // Send a POST request to your API
        const response = await fetch('https://profit-backend.onrender.com/api/contact/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Success:', data);

          // Show success message
          setSuccessMessage("Form submitted successfully!");
          setErrorMessage("");

          // Clear the form fields
          setName("");
          setEmail("");
          setMessage("");
        } else {
          console.error('Error:', response.statusText);
          setErrorMessage('There was an error submitting the form.');
          setSuccessMessage("");
        }
      } catch (error) {
        console.error('Error:', error);
        setErrorMessage('There was an error submitting the form.');
        setSuccessMessage("");
      }
    }

    setValidated(true);
  };

  return (
    <form validated={validated.toString()} onSubmit={handleSubmit}>
      {errorMessage && <p className="text-red-600 mb-4">{errorMessage}</p>}
      {successMessage && <p className="text-green-600 mb-4">{successMessage}</p>}

      <div className="mb-4">
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="min-h-[48px] leading-[48px] bg-[#F2F6FD] dark:bg-[#2A384C] border border-transparent rounded-xl focus:outline-none focus:border-[#86b7fe] w-full px-5"
          placeholder="Enter Name"
          required
        />
      </div>
      <div className="mb-4">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="min-h-[48px] leading-[48px] bg-[#F2F6FD] dark:bg-[#2A384C] border border-transparent rounded-xl focus:outline-none focus:border-[#86b7fe] w-full px-5"
          placeholder="Enter Email"
          required
        />
      </div>
      <div className="mb-4">
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          name="message"
          className="min-h-[48px] leading-[48px] bg-[#F2F6FD] dark:bg-[#2A384C] border border-transparent rounded-xl focus:outline-none focus:border-[#86b7fe] w-full px-5"
          placeholder="Enter Message"
          rows="4"
          required
        ></textarea>
      </div>
      <div className="text-end">
        <button
          type="submit"
          className="bg-[#00416A] hover:bg-opacity-90 text-white px-9 py-3 rounded-md mb-4"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

const ContactFormCard = () => (
  <div className="bg-white dark:bg-[#162231] shadow-xl rounded-2xl p-6 md:p-12">
    <h2 className="text-2xl md:text-[45px] leading-none font-bold mb-4">
      Contact Us
    </h2>
    <p className="text-lg mb-12">
      Feel free to reach us.
    </p>

    <ContactForm />
  </div>
);

const ContactUs3 = () => {
  return (
    <section className="ezy__contact3 light py-14 md:py-24 bg-white dark:bg-[#0b1727] text-zinc-900 dark:text-white overflow-hidden">
      <div className="container px-4">
        <div className="grid grid-cols-12 py-6 lg:gap-8">
          <div className="col-span-12 lg:col-span-6 lg:order-2 mb-4 lg:mb-0">
            <div
              className="bg-center bg-no-repeat bg-cover rounded-2xl min-h-[150px] h-full object-cover"
              style={{
                backgroundImage: `url(${ContactImage})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                height: '100vh',
                width: '100%',
              }}
            ></div>
          </div>
          <div className="col-span-12 lg:col-span-6">
            <ContactFormCard />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs3;


