// import React, { useState } from 'react';
// import axios from 'axios';

// const ResetPassword = () => {
//     const [email, setEmail] = useState('');
//     const [otp, setOtp] = useState('');
//     const [password, setPassword] = useState('');
//     const [message, setMessage] = useState('');
//     const [error, setError] = useState('');
//     const [step, setStep] = useState('request');  // 'request', 'verify', or 'reset'

//     const handleRequestOtp = async (e) => {
//         e.preventDefault();
//         try {
//             const response = await axios.post('http://127.0.0.1:8000/api/password-reset-request/', { email });
//             setMessage(response.data.message);
//             setStep('verify');  // Switch to verify OTP step
//         } catch (error) {
//             setError(error.response.data.message || 'An error occurred.');
//         }
//     };

//     const handleVerifyOtp = async (e) => {
//         e.preventDefault();
//         try {
//             const response = await axios.post('http://127.0.0.1:8000/api/password-reset-verify/', { email, otp });
//             setMessage(response.data.message);
//             setStep('reset');  // Switch to reset password step
//         } catch (error) {
//             setError(error.response.data.otp || 'An error occurred.');
//         }
//     };

//     const handleResetPassword = async (e) => {
//         e.preventDefault();
//         if (password.length < 8 || !/[a-zA-Z]/.test(password) || !/[0-9]/.test(password)) {
//             setError('Password must be at least 8 characters long and include both letters and numbers.');
//             return;
//         }

//         try {
//             await axios.post('http://127.0.0.1:8000/api/password-reset-confirm/', { email, password });
//             setMessage('Password successfully reset. You can now log in.');
//         } catch (error) {
//             setError('An error occurred while resetting the password.');
//         }
//     };

//     return (
//         <div className="flex justify-center items-center min-h-screen bg-gray-100">
//             <div className="w-full max-w-md p-6 bg-white shadow-md rounded">
//                 <h2 className="text-2xl font-bold mb-4">Reset Password</h2>
//                 {step === 'request' ? (
//                     <form onSubmit={handleRequestOtp} className="space-y-4">
//                         <div>
//                             <label className="block text-sm font-medium text-gray-700">Email:</label>
//                             <input
//                                 type="email"
//                                 value={email}
//                                 onChange={(e) => setEmail(e.target.value)}
//                                 required
//                                 className="mt-1 block w-full border border-gray-300 rounded px-3 py-2"
//                             />
//                         </div>
//                         <button
//                             type="submit"
//                             className="w-full bg-[#00416A] text-white py-2 px-4 rounded hover:bg-blue-600"
//                         >
//                             Request OTP
//                         </button>
//                     </form>
//                 ) : step === 'verify' ? (
//                     <form onSubmit={handleVerifyOtp} className="space-y-4">
//                         <div>
//                             <label className="block text-sm font-medium text-gray-700">OTP:</label>
//                             <input
//                                 type="text"
//                                 value={otp}
//                                 onChange={(e) => setOtp(e.target.value)}
//                                 required
//                                 className="mt-1 block w-full border border-gray-300 rounded px-3 py-2"
//                             />
//                         </div>
//                         <button
//                             type="submit"
//                             className="w-full bg-[#00416A] text-white py-2 px-4 rounded hover:bg-blue-600"
//                         >
//                             Verify OTP
//                         </button>
//                     </form>
//                 ) : (
//                     <form onSubmit={handleResetPassword} className="space-y-4">
//                         <div>
//                             <label className="block text-sm font-medium text-gray-700">New Password:</label>
//                             <input
//                                 type="password"
//                                 value={password}
//                                 onChange={(e) => setPassword(e.target.value)}
//                                 required
//                                 className="mt-1 block w-full border border-gray-300 rounded px-3 py-2"
//                             />
//                         </div>
//                         <button
//                             type="submit"
//                             className="w-full bg-[#00416A] text-white py-2 px-4 rounded hover:bg-blue-600"
//                         >
//                             Reset Password
//                         </button>
//                     </form>
//                 )}
//                 {message && <p className="mt-4 text-green-600">{message}</p>}
//                 {error && <p className="mt-4 text-red-600">{error}</p>}
//             </div>
//         </div>
//     );
// };

// export default ResetPassword;

import React, { useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; 

const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState(Array(6).fill(''));
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [step, setStep] = useState('request');  // 'request', 'verify', or 'reset'
    const navigate = useNavigate();
    const otpRefs = useRef([]);

    const handleRequestOtp = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://profit-backend.onrender.com/api/password-reset-request/', { email });
            setMessage(response.data.message);
            setStep('verify');
        } catch (error) {
            setError(error.response.data.message || 'An error occurred.');
        }
    };

    const handleOtpChange = (e, index) => {
        const value = e.target.value;
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (value && index < otp.length - 1) {
            otpRefs.current[index + 1].focus(); // Focus the next input
        }
    };

    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://profit-backend.onrender.com/api/password-reset-verify/', { email, otp: otp.join('') });
            setMessage(response.data.message);
            setStep('reset');
        } catch (error) {
            setError(error.response.data.otp || 'An error occurred.');
        }
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        if (password.length < 8 || !/[a-zA-Z]/.test(password) || !/[0-9]/.test(password) || !/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
            setError('Password must be at least 8 characters long and include letters, numbers, and at least one special character (e.g., A@vfd13rs).');
            return;
        }

        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        try {
            await axios.post('https://profit-backend.onrender.com/api/password-reset-confirm/', { email, password });
            setMessage('Password successfully reset. You can now log in.');
            navigate('/login');
        } catch (error) {
            setError('An error occurred while resetting the password.');
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="w-full max-w-md p-6 bg-white shadow-md rounded">
                <h2 className="text-2xl font-bold mb-4">Reset Password</h2>
                {step === 'request' && (
                    <form onSubmit={handleRequestOtp} className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Email:</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="mt-1 block w-full border border-gray-300 rounded px-3 py-2"
                            />
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-[#00416A] text-white py-2 px-4 rounded hover:bg-blue-600"
                        >
                            Request OTP
                        </button>
                    </form>
                )}

                {step === 'verify' && (
                    <div className="fixed inset-0 flex items-center justify-center z-50 mt-20">
                        <div className="relative z-50 max-w-md mx-auto bg-white p-6 rounded-lg shadow-lg">
                            <h5 className="text-xl font-medium text-gray-800 mb-4">Enter OTP</h5>
                            <p className="mb-4 text-center text-gray-700">An OTP has been sent to your email: <strong>{email}</strong></p>
                            <div className="flex justify-center mb-4">
                                {otp.map((digit, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        maxLength="1"
                                        className="otp-input border rounded text-center text-lg m-1 p-2 w-12"
                                        value={digit}
                                        onChange={(e) => handleOtpChange(e, index)}
                                        ref={(el) => (otpRefs.current[index] = el)}
                                    />
                                ))}
                            </div>
                            <button
                                onClick={handleVerifyOtp}
                                className="w-full bg-gradient-to-br from-green-400 to-blue-600 text-white px-4 py-2 rounded-full"
                            >
                                Verify OTP
                            </button>
                        </div>
                    </div>
                )}

                {step === 'reset' && (
                    <form onSubmit={handleResetPassword} className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">New Password:</label>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                className="mt-1 block w-full border border-gray-300 rounded px-3 py-2"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Confirm Password:</label>
                            <input
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                                className="mt-1 block w-full border border-gray-300 rounded px-3 py-2"
                            />
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-[#00416A] text-white py-2 px-4 rounded hover:bg-blue-600"
                        >
                            Reset Password
                        </button>
                    </form>
                )}

                {message && <p className="mt-4 text-green-600">{message}</p>}
                {error && <p className="mt-4 text-red-600">{error}</p>}
            </div>
        </div>
    );
};

export default ResetPassword;
