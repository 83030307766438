// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Link } from 'react-router-dom';

// function Coinlist01() {
//   const [dataCoin, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     axios.get('https://profit-backend.onrender.com/api/banks/')
//       .then(response => {
//         const sortedData = response.data.sort((a, b) => parseFloat(b.profitrate) - parseFloat(a.profitrate));
//         setData(sortedData);
//         setLoading(false);
//       })
//       .catch(error => {
//         setError(error);
//         setLoading(false);
//       });
//   }, []);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error: {error.message}</div>;
//   }

//   return (
//     <section className="coin-list py-8">
//       <div className="container mx-auto px-4">
//         <div className="overflow-x-auto">
//           <div className="shadow-lg rounded-lg overflow-hidden mx-4 md:mx-10">
//             <table className="w-full min-w-[800px] z-10">
//               <thead>
//                 <tr className="bg-[#00416A] text-white">
//                   <th className="w-1/12 py-2 px-2 sm:px-4"></th>
//                   <th className="w-1/4 py-2 px-2 sm:px-4 text-left font-bold uppercase">Bank Name</th>
//                   <th className="w-1/4 py-2 px-2 sm:px-4 text-left font-bold uppercase">Saving Account</th>
//                   <th className="w-1/6 py-2 px-2 sm:px-4 text-left font-bold uppercase">TDR-1 month</th>
//                   <th className="w-1/6 py-2 px-2 sm:px-4 text-left font-bold uppercase">TDR-3 month</th>
//                   <th className="w-1/6 py-2 px-2 sm:px-4 text-left font-bold uppercase">TDR-annual</th>
//                 </tr>
//               </thead>
//               <tbody className="bg-white">
//                 {dataCoin.map(coin => (
//                   <tr key={coin.id} className="border-b border-gray-200">
//                     <td className="py-1 px-2 sm:px-4"><span className="icon-star text-[#00416A]"></span></td>
//                     <td className="py-1 px-2 sm:px-4">
//                       <Link to="#">
//                         <span className="bank-name text-[#00416A]">{coin.name}</span>
//                       </Link>
//                     </td>
//                     <td className="py-1 px-2 sm:px-4 font-bold text-[#00416A]">{coin.profitrate}</td>
//                     <td className="py-1 px-2 sm:px-4 font-bold text-[#00416A]">{coin.profitrate_1month}</td>
//                     <td className="py-1 px-2 sm:px-4 font-bold text-[#00416A]">{coin.profitrate_3month}</td>
//                     <td className="py-1 px-2 sm:px-4 font-bold text-[#00416A]">{coin.profitrate_annual}</td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

// export default Coinlist01;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

function Coinlist01() {
  const [dataCoin, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get('https://profit-backend.onrender.com/api/banks/')
      .then(response => {
        const sortedData = response.data.sort((a, b) => parseFloat(b.profitrate) - parseFloat(a.profitrate));
        setData(sortedData);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <section className="coin-list py-8">
      <div className="container mx-auto px-4">
        <div className="overflow-x-auto">
          <div className="shadow-lg rounded-lg overflow-hidden mx-4 md:mx-10">
            <div className="overflow-x-auto">
              <table className="w-full min-w-[800px] z-10">
                <thead>
                  <tr className="bg-[#00416A] text-white">
                    <th className="w-1/12 py-2 px-2 sm:px-4"></th>
                    <th className="w-1/4 py-2 px-2 sm:px-4 text-left font-bold uppercase">Bank Name</th>
                    <th className="w-1/4 py-2 px-2 sm:px-4 text-left font-bold uppercase">Saving Account</th>
                    <th className="w-1/6 py-2 px-2 sm:px-4 text-left font-bold uppercase">TDR-1 month</th>
                    <th className="w-1/6 py-2 px-2 sm:px-4 text-left font-bold uppercase">TDR-3 month</th>
                    <th className="w-1/6 py-2 px-2 sm:px-4 text-left font-bold uppercase">TDR-annual</th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {dataCoin.map(coin => (
                    <tr key={coin.id} className="border-b border-gray-200">
                      <td className="py-1 px-2 sm:px-4"><span className="icon-star text-[#00416A]"></span></td>
                      <td className="py-1 px-2 sm:px-4">
                        <Link to="#">
                          <span className="bank-name text-[#00416A]">{coin.name}</span>
                        </Link>
                      </td>
                      <td className="py-1 px-2 sm:px-4 font-bold text-[#00416A]">{coin.profitrate}</td>
                      <td className="py-1 px-2 sm:px-4 font-bold text-[#00416A]">{coin.profitrate_1month}</td>
                      <td className="py-1 px-2 sm:px-4 font-bold text-[#00416A]">{coin.profitrate_3month}</td>
                      <td className="py-1 px-2 sm:px-4 font-bold text-[#00416A]">{coin.profitrate_annual}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Coinlist01;
