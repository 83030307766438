// import React, { useState } from 'react';
// import './Chatbot.css';
// import chatIcon from './chat-icon.png';
// import whatsappIcon from './whatsapp-icon.png'; // Add WhatsApp icon image in the components folder

// const Chatbot = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [messages, setMessages] = useState([]);
//   const [currentSlider, setCurrentSlider] = useState(0);
//   const [email, setEmail] = useState('');
//   const [isEmailEntered, setIsEmailEntered] = useState(false);

//   const questions = [
//     { question: "Where should I invest my funds for the highest profit?", answer: "To find the best investment options for high profits, I recommend using our calculators to compare the profit rates of various banks. Please consult your financial consultant for personalized advice and ensure you have all necessary information before shifting your funds." },
//     { question: "What are the current profit rates for savings accounts?", answer: "The current profit rates for savings accounts vary by bank. You can view up-to-date comparisons using our website's calculators. Please consult your financial consultant and verify the rates before making any decisions." },
//     { question: "How can I maximize my returns on a fixed deposit?", answer: "To maximize returns on a fixed deposit, compare the profit rates offered by different banks using our calculators. Please consult your financial consultant to choose the best option and gather all relevant information before shifting your funds." },
//     { question: "Is it safe to transfer large sums of money to a new bank?", answer: "Transferring large sums of money should always be done with caution. Ensure you choose a reputable bank. Consult your financial consultant for advice and make sure you have all the necessary information before proceeding." },
//     { question: "What are the best options for short-term investments?", answer: "For short-term investments, Term Deposit Receipts (TDRs) can be a good option. Use our calculators to compare rates from different banks. Please consult your financial consultant to choose the best option and gather all relevant information before shifting your funds." },
//     { question: "Which bank offers the highest profit rate for a 1-year fixed deposit?", answer: "You can use our calculators to compare the profit rates for 1-year fixed deposits across different banks. I recommend consulting your financial consultant for the most accurate and personalized advice. Ensure you have all necessary information before transferring your funds." },
//     { question: "How do I compare profit rates between banks?", answer: "You can compare profit rates between banks using our website's calculators. Please consult your financial consultant for detailed advice and ensure you have all the information before making any decisions." },
//     { question: "Are there any hidden fees when investing in a new bank?", answer: "Hidden fees can vary by bank and account type. It's important to read the fine print and consult with your financial consultant. Always double-check before transferring your funds." },
//     { question: "What documents do I need to open a high-yield savings account?", answer: "To open a high-yield savings account, you generally need identification proof, address proof, and possibly income proof. Please consult your financial consultant to ensure you have all the necessary documents." },
//     { question: "Can I transfer my funds internationally with a local bank?", answer: "Many local banks offer international fund transfer services. It's best to consult your financial consultant and get all the details to ensure a smooth process." },
//     { question: "How often are the profit rates updated on your website?", answer: "Our website updates profit rates regularly to reflect the most recent rates available from the banks, typically on a daily basis. For the most accurate information, please consult your financial consultant and verify the rates before making any decisions." },
//     { question: "What should I consider before shifting my funds to a new bank?", answer: "Before shifting your funds to a new bank, consider the profit rates, any associated fees, and the bank's reputation. Use our calculators to compare rates. Please consult your financial consultant to gather all necessary information and ensure it aligns with your financial goals." },
//     { question: "If I shift my own funds from one bank to another, will there be any issues with my FBR records?", answer: "Shifting funds between your own accounts in different banks typically does not cause issues with your FBR (Federal Board of Revenue) records. However, it's always a good idea to consult with your financial advisor for specific advice regarding your situation and to ensure all transactions are compliant with regulations." },
//     // Add more questions as needed
//   ];

//   // Split questions into chunks of 3
//   const chunkedQuestions = [];
//   for (let i = 0; i < questions.length; i += 3) {
//     chunkedQuestions.push(questions.slice(i, i + 3));
//   }

//   const handleQuestionClick = (question) => {
//     setMessages([...messages, { sender: 'user', text: question.question }, { sender: 'bot', text: question.answer }]);
//   };

//   const toggleChatbot = () => {
//     setIsOpen(!isOpen);
//   };

//   const handleNext = () => {
//     setCurrentSlider((prev) => (prev + 1) % chunkedQuestions.length);
//   };

//   const handlePrev = () => {
//     setCurrentSlider((prev) => (prev - 1 + chunkedQuestions.length) % chunkedQuestions.length);
//   };

//   const handleEmailSubmit = (e) => {
//     e.preventDefault();
//     if (email) {
//       setIsEmailEntered(true);
//     }
//   };

//   const openWhatsApp = () => {
//     window.open('https://wa.me/+923290775775', '_blank');
//   };

//   return (
//     <div>
//       <div className={`chatbot-container ${isOpen ? 'open' : ''}`}>
//         <div className="chatbot-header">
//           FAQ Chatbot
//           <button className="close-button" onClick={toggleChatbot}>×</button>
//         </div>
//         {!isEmailEntered ? (
//           <form className="email-form" onSubmit={handleEmailSubmit}>
//             <label htmlFor="email">Please enter your email address:</label>
//             <input
//               type="email"
//               id="email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               required
//             />
//             <button type="submit">Submit</button>
//           </form>
//         ) : (
//           <>
//             <div className="chatbot-messages">
//               {messages.map((message, index) => (
//                 <div key={index} className={`message ${message.sender}`}>
//                   {message.text}
//                 </div>
//               ))}
//             </div>
//             <div className="chatbot-questions">
//               {chunkedQuestions[currentSlider].map((question, index) => (
//                 <button key={index} onClick={() => handleQuestionClick(question)}>
//                   {question.question}
//                 </button>
//               ))}
//               {currentSlider === chunkedQuestions.length - 1 && (
//                 <button className="whatsapp-button" onClick={openWhatsApp}>
//                   <img src={whatsappIcon} alt="WhatsApp" className="whatsapp-icon" />
//                   Chat with us on WhatsApp
//                 </button>
//               )}
//             </div>
//             <div className="chatbot-navigation">
//               <button onClick={handlePrev} disabled={currentSlider === 0}>Previous</button>
//               <button onClick={handleNext} disabled={currentSlider === chunkedQuestions.length - 1}>Next</button>
//             </div>
//           </>
//         )}
//       </div>
//       <div className={`chat-icon ${isOpen ? 'hidden' : ''}`} onClick={toggleChatbot}>
//         <img src={chatIcon} alt="Chat" />
//       </div>
//     </div>
//   );
// };

// export default Chatbot;

import React, { useState, useEffect, useRef } from 'react';
import './Chatbot.css';
import chatIcon from './chat-icon.png';
import whatsappIcon from './whatsapp-icon.png';

const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [currentSlider, setCurrentSlider] = useState(0);
  const [email, setEmail] = useState('');
  const [isEmailEntered, setIsEmailEntered] = useState(false);

  const chatbotRef = useRef(null);

  const questions = [
    { question: "Where should I invest my funds for the highest profit?", answer: "To find the best investment options for high profits, I recommend using our calculators to compare the profit rates of various banks. Please consult your financial consultant for personalized advice and ensure you have all necessary information before shifting your funds." },
    // ...other questions
  ];

  const chunkedQuestions = [];
  for (let i = 0; i < questions.length; i += 3) {
    chunkedQuestions.push(questions.slice(i, i + 3));
  }

  const handleQuestionClick = (question) => {
    setMessages([...messages, { sender: 'user', text: question.question }, { sender: 'bot', text: question.answer }]);
  };

  const toggleChatbot = () => {
    setIsOpen(!isOpen);
  };

  const handleNext = () => {
    setCurrentSlider((prev) => (prev + 1) % chunkedQuestions.length);
  };

  const handlePrev = () => {
    setCurrentSlider((prev) => (prev - 1 + chunkedQuestions.length) % chunkedQuestions.length);
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    if (email) {
      setIsEmailEntered(true);
    }
  };

  const openWhatsApp = () => {
    window.open('https://wa.me/+923290775775', '_blank');
  };

  // Close chatbot if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (chatbotRef.current && !chatbotRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div ref={chatbotRef} className={`chatbot-container ${isOpen ? 'open' : ''}`}>
        <div className="chatbot-header">
          FAQ Chatbot
          <button className="close-button" onClick={toggleChatbot}>×</button>
        </div>
        {!isEmailEntered ? (
          <form className="email-form" onSubmit={handleEmailSubmit}>
            <label htmlFor="email">Please enter your email address:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit">Submit</button>
          </form>
        ) : (
          <>
            <div className="chatbot-messages">
              {messages.map((message, index) => (
                <div key={index} className={`message ${message.sender}`}>
                  {message.text}
                </div>
              ))}
            </div>
            <div className="chatbot-questions">
              {chunkedQuestions[currentSlider].map((question, index) => (
                <button key={index} onClick={() => handleQuestionClick(question)}>
                  {question.question}
                </button>
              ))}
              {currentSlider === chunkedQuestions.length - 1 && (
                <button className="whatsapp-button" onClick={openWhatsApp}>
                  <img src={whatsappIcon} alt="WhatsApp" className="whatsapp-icon" />
                  Chat with us on WhatsApp
                </button>
              )}
            </div>
            <div className="chatbot-navigation">
              <button onClick={handlePrev} disabled={currentSlider === 0}>Previous</button>
              <button onClick={handleNext} disabled={currentSlider === chunkedQuestions.length - 1}>Next</button>
            </div>
          </>
        )}
      </div>
      <div className={`chat-icon ${isOpen ? 'hidden' : ''}`} onClick={toggleChatbot}>
        <img src={chatIcon} alt="Chat" />
      </div>
    </div>
  );
};

export default Chatbot;
